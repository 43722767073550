import React from 'react';
import {useSettings} from '~/components/felles/Settings';
import {Heading1, Paragraph} from '@sb1/ffe-core-react';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';

interface Params {
    onLoginComplete: () => void;
}

const Login: React.FunctionComponent<Params> = ({onLoginComplete}) => {
    const { login_url } = useSettings();
    const hostname = window.location.port ? `${window.location.hostname}:${window.location.port}` : window.location.hostname;
    const goto = `${window.location.protocol}//${hostname}/iframeCompleted.html`;
    (window as any).iframeCompleted = async () => {
        onLoginComplete();
    };
    return <Grid>
        <GridRow className={'ksmb--padding-top'}>
            <GridCol lg={{ cols: 8, offset: 2 }} center={true} className={'ksmb--flex-column'}>
                <Heading1>Logg inn</Heading1>
                <Paragraph textCenter={true}>
                    For å kjøpe produkter må du logge inn med BankID.
                    Slik bekrefter vi din rolle i bedriften mot Brønnøysundregistrene.
                </Paragraph>
            </GridCol>
            <GridCol>
                <iframe className="ksmb-signering-login-frame" src={`${login_url}&goto=${goto}`} title="Bankid"></iframe>
            </GridCol>
        </GridRow>
    </Grid>
};

export default Login;
