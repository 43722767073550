import React, {PropsWithChildren} from 'react';
import Login from '~/components/signering/Login';
import {useInnloggetBruker} from '~/components/felles/InnloggetBruker';

const Innlogget: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {

    const { innlogget, refresh } = useInnloggetBruker();
    if (!innlogget) {
        return <Login onLoginComplete={() => { refresh(); }} />
    }
    return <>{children}</>
};

export default Innlogget;
