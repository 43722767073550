import React, {RefObject, useEffect, useRef, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Steg1 from '~/components/steg1/Steg1';
import Produktspesifikasjoner from '~/components/produkt/Produktspesifikasjoner';
import Header from '~/components/felles/Header';
import Footer from '~/components/felles/Footer';
import TrengerDuHjelp from '~/components/felles/TrengerDuHjelp';
import GridDevTool from '~/components/devTools/GridDevTool';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import Chat from '~/components/felles/Chat';
import ChatWindow from '~/components/felles/chat-window/ChatWindow';
import BliOppringt from '~/components/bliOppringt/BliOppringt';
import TilbudProvider from '~/components/tilbud/TilbudProvider';
import {FormSubmitStatus, trackFormCompleted} from '@sparebank1/pensjon-bm-analytics';
import NyttTilbudAnsattinformasjon from '~/components/ansatte/NyttTilbudAnsattinformasjon';
import EpostSendt from '~/components/kvittering/EpostSendt';
import Innlogget from '~/components/felles/Innlogget';
import OppsummeringRoutes from '~/components/oppsummering/OppsummeringRoutes';
import SigneringRoutes from '~/components/signering/SigneringRoutes';
import Flyttefullmakt from "~/components/flyttefullmakt/Flyttefullmakt";
import AntallAnsatte from "~/components/steg1/AntallAnsatte";
import ApplikasjonenErOppdatert from "~/utils/ApplikasjonenErOppdatert";

export interface FormContextProps {
    formStatus: FormSubmitStatus;
    setFormStatus: (s: FormSubmitStatus) => void;
    ferdig: boolean;
    setFerdig: (f: boolean) => void;
}
export const FormContext = React.createContext<FormContextProps>({
    formStatus: FormSubmitStatus.SUCCESS,
    setFormStatus: (_status: FormSubmitStatus) => { },
    ferdig: false,
    setFerdig: (_ferdig: boolean) => { }
});

interface Props {
    kontaktskjemaApent: boolean;
    setKontaktskjemaApent: (boolean) => void;
    kontaktSkjemaRef: RefObject<HTMLDivElement>;
}

export const tilFlytting = (orgnr, antall) => `/flyttefullmakt/${orgnr}/${antall}`;
export const tilAnsatte = (orgnr, antall, flytting) => `/ansatte/${orgnr}/${antall}${flytting ? '?flytting=true' : ''}`;

const Routing: React.FunctionComponent<Props> = ({kontaktSkjemaRef, kontaktskjemaApent, setKontaktskjemaApent}) => {
    const chatWindowRef = useRef<ChatWindow>(null);
    const { feilmelding } = useErrorHandler();
    const [ferdig, setFerdig] = useState<boolean>(false);
    const [formStatus, setFormStatus] = useState<FormSubmitStatus>(FormSubmitStatus.SUCCESS);

    const toggleKontaktskjema = () => {
        setKontaktskjemaApent(!kontaktskjemaApent);
    };

    const toggleChat = () => {
        if (chatWindowRef.current) {
            chatWindowRef.current.onToggleOpen();
        }
    };

    useEffect(() => {
        if (feilmelding) {
            setFormStatus(FormSubmitStatus.ERROR);
            setFerdig(true);
        }
    }, [feilmelding]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (ferdig) {
            trackFormCompleted(formStatus);
            //nullstill og åpne kontaktskjema
            setFerdig(false);
            if (formStatus === FormSubmitStatus.ERROR) {
                setFormStatus(FormSubmitStatus.SUCCESS);
            }
        }
    }, [ferdig, formStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    //Denne vil bare bli logget til adobe hvis det ikke har blitt logget noen annen formCompleted tidligere
    useEffect(() => {
        return (() => {
            trackFormCompleted(FormSubmitStatus.USER_CANCEL);
        });
    }, []);

    return <FormContext.Provider value={{ formStatus, setFormStatus, ferdig, setFerdig }}>
        <div className="ksmb-innhold">
            <GridDevTool />
            <Header />
            <main>
                <Routes>
                    <Route path={'/'} element={<Steg1 />} />
                    <Route path={'/org/:orgnr'} element={<AntallAnsatte />} />
                    <Route path={'/ansatte/:orgnr/:antall'} element={<NyttTilbudAnsattinformasjon />} />
                    <Route path={'/flyttefullmakt/:orgnr/:antall'} element={<Flyttefullmakt />} />
                    <Route path={'/produkt/:tilbudId'} element={
                        <TilbudProvider>
                            <Produktspesifikasjoner endreModus={false}/>
                        </TilbudProvider>
                    } />
                    <Route path={'/oppsummering/:tilbudId/*'} element={
                        <TilbudProvider>
                            <OppsummeringRoutes />
                        </TilbudProvider>
                    } />
                    <Route path={'/epostsendt/:tilbudId'} element={
                        <TilbudProvider>
                            <EpostSendt />
                        </TilbudProvider>
                    } />
                    <Route path={'/innlogget/signering/:tilbudId/*'} element={
                        <Innlogget>
                            <TilbudProvider>
                                <SigneringRoutes />
                            </TilbudProvider>
                        </Innlogget>
                    } />
                    <Route path='*' element={<Navigate to="/" />} />
                </Routes>
            </main>
            <aside>
                <TrengerDuHjelp
                    kontaktskjemaApent={kontaktskjemaApent}
                    toggleKontaktskjema={toggleKontaktskjema}
                    toggleChat={toggleChat} />
                <div id="kontaktskjema" ref={kontaktSkjemaRef}>
                    {(kontaktskjemaApent) &&
                        <BliOppringt feilmelding={feilmelding} />
                    }
                </div>
            </aside>
        </div>
        <div id="chat" role="region" aria-label="Chat">
            <Chat chatWindowRef={chatWindowRef} />
        </div>
        <Footer />
        <ApplikasjonenErOppdatert />
    </FormContext.Provider>
}
export default Routing;
