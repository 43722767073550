import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {hentTilbud} from '~/components/tilbud/TilbudService';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import {GridCol} from '@sb1/ffe-grid-react';
import { Spinner } from '@sb1/ffe-spinner-react';
import {TilbudContext} from '~/components/tilbud/TilbudContext';
import {useInnloggetBruker} from '~/components/felles/InnloggetBruker';

type Params = 'tilbudId';

const TilbudProvider: React.FunctionComponent<PropsWithChildren<{}>> = ({children}) => {
    const {tilbudId} = useParams<Params>();
    const { catchError } = useErrorHandler()
    const tilbudContext = useContext(TilbudContext);
    const { refresh } = useInnloggetBruker();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        if (tilbudId && (!tilbudContext.tilbud || tilbudContext.tilbudId !== tilbudId)) {
            setLoading(true);
            hentTilbud(tilbudId).then((tilbudRespons) => {
                setLoading(false);
                tilbudContext.setTilbud(tilbudRespons);
                tilbudContext.setTilbudId(tilbudId);
            }).catch(error => {
                setLoading(false);
                if (error.status === 401) {
                    refresh();
                } else {
                    catchError(error);
                }
            });
        }
    }, [tilbudId]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {loading &&
                <GridCol center={true}>
                    <Spinner large={true} className="ksmb-spinner--lg" loadingText="Henter tilbud..." />
                </GridCol>
            }
            {tilbudContext.tilbud && tilbudContext.tilbudId === tilbudId && (
                <>{children}</>
            )
            }
        </>
    );

};

export default TilbudProvider;
