import React, {PropsWithChildren, useContext} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import {get} from '~/utils/fetch';
import { Spinner } from "@sb1/ffe-spinner-react";

export interface InnloggetBruker {
    innlogget: boolean;
    fnr?: string;
    fornavn?: string;
    etternavn?: string;
    refresh: () => void;
}

const hentInnloggetBruker = async () => {
    return get('/api/innloggetbruker') as Promise<InnloggetBruker>;
}

const InnloggetBrukerContext = React.createContext<InnloggetBruker>({innlogget: false, refresh: () => {}});

export const useInnloggetBruker = (): InnloggetBruker => {
    return useContext(InnloggetBrukerContext);
}

const InnloggetBrukerProvider: React.FunctionComponent<PropsWithChildren<{}>> = ({children}) => {
    const [innloggetBruker, setInnloggetBruker] = React.useState<InnloggetBruker>();

    const sjekkInnloggetBruker = () => {
        hentInnloggetBruker().then(innloggetBrukerResponse => {
            if (innloggetBruker === undefined || JSON.stringify(innloggetBrukerResponse) !== JSON.stringify(innloggetBruker)) {
                setInnloggetBruker({...innloggetBrukerResponse, innlogget: !!innloggetBrukerResponse.fnr?.length, refresh: sjekkInnloggetBruker});
            }
        }).catch(() => undefined);
        return;
    };

    useIdleTimer({
        onActive: sjekkInnloggetBruker,
        timeout: 10000
    })

    if (innloggetBruker === null || innloggetBruker === undefined) {
        sjekkInnloggetBruker();
        return <div className="ksmb-spinner-container--fullscreen">
            <Spinner large={true} loadingText="Vennligst vent..."/>
        </div>
    }
    return <InnloggetBrukerContext.Provider value={innloggetBruker}>
        {children}
    </InnloggetBrukerContext.Provider>
}

export default InnloggetBrukerProvider;
