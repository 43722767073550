import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Route, Routes} from 'react-router';
import InnloggetBrukerProvider from '~/components/felles/InnloggetBruker';
import Settings from '~/components/felles/Settings';
import Utlogget from '~/components/felles/Utlogget';
import TrackingSetup from '~/components/tracking/TrackingSetup';
import TilbudContextProvider from '~/components/tilbud/TilbudContext';

import '~/less/index.less';
import Hovedflyt from '~/Hovedflyt';
import {RecoilRoot} from "recoil";

export const maksAntallAnsatte = 24;

function App() {
    const basename = process.env.NODE_ENV === 'development' ? '/' : '/ksmb/';

    return <BrowserRouter basename={basename}>
        <RecoilRoot>
            <Settings>
                <TilbudContextProvider>
                    <TrackingSetup />
                    <InnloggetBrukerProvider>
                        <Routes>
                            <Route path={'/utlogget'} element={<Utlogget signert={false} />} />
                            <Route path={'*'} element={<Hovedflyt />} />
                        </Routes>
                    </InnloggetBrukerProvider>
                </TilbudContextProvider>
            </Settings>
        </RecoilRoot>
    </BrowserRouter>
}

export default App;
