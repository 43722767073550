import React from 'react';
//import ShowGrid from '@sb1/ffe-grid-react/lib/show-grid';

export default class GridDevTool extends React.Component<{}, any> {
    constructor(props) {
        super(props);
        this.onToggleGrid = this.onToggleGrid.bind(this);
    }

    onToggleGrid() {
        (window as any).showGrid();
    }

    componentDidMount() {
        if (process.env.NODE_ENV === 'development') {
            //(window as any).showGrid = ShowGrid;
        }
    }

    render() {
        if (process.env.NODE_ENV !== 'development') {
            return null;
        }
        return (
            <button type="button" onClick={() => this.onToggleGrid()}
                style={{ position: 'fixed', zIndex: 9999 }}>
                Toggle grid
            </button>
        );
    }
}
