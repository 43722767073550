import React from 'react';
import classnames from 'classnames';
import { Paragraph, SmallText } from '@sb1/ffe-core-react';
import { formatCurrency } from '@sb1/ffe-formatters';

export interface VisPrisProps {
    belop: number;
    beregner: boolean;
    hvitTekst?: boolean;
    prefix?: string;
    marginBottom?: boolean;
    marginTop?: boolean;
}

const defaultProps: VisPrisProps = {
    belop: 0,
    beregner: false,
    hvitTekst: false,
    prefix: '',
    marginBottom: true,
    marginTop: false
};

const VisPris: React.FunctionComponent<VisPrisProps> = ({ belop, beregner, hvitTekst, prefix, marginBottom, marginTop }) => {
    return (
        <Paragraph className={
            classnames(
                {
                    'ksmb-text--white':
                        hvitTekst
                },
                { 'ksmb-body-paragraph--no-margin-bottom': !marginBottom },
                { 'ksmb-body-paragraph--margin-top': marginTop })}>
            <span className={
                classnames('ksmb-pris__spinner',
                    { 'ksmb-pris__spinner--white': hvitTekst },
                    { 'ksmb-transitions-fade-out': !beregner },
                    { 'ksmb-transitions-fade-in': beregner }
                )} />
            <span className={
                classnames('ffe-h3 ksmb-pris__sum',
                    { 'ksmb-transitions-fade-out': beregner },
                    { 'ksmb-transitions-fade-in': !beregner },
                    { 'ksmb-text--white': hvitTekst })}>
                {formatCurrency(belop, { prefix: prefix ? prefix : '' })}</span>
            <SmallText className={
                classnames(
                    {
                        'ksmb-text--white':
                            hvitTekst
                    })}> per år</SmallText>
        </Paragraph>
    );
};

VisPris.defaultProps = defaultProps;

export default VisPris;
