import Cookie from 'js-cookie';
import {setBackendVersjon} from "~/utils/ApplikasjonenErOppdatert";

const now = new Date();

const defaultHeaders = {
    Accept: 'application/json',
    pragma: 'no-cache',
    'cache-control': 'no-cache:no-store:must-revalidate',
    Expires: '0',
    'X-JS-SESSION-ID': `${now.getDate()}_${now.getHours()}_${now.getSeconds()}_${now.getMilliseconds()}`
};

export const setLogContext = navn => {
    defaultHeaders['X-CONTEXT'] = navn;
};

export const setLogOrgnr = orgnr => {
    defaultHeaders['CurrentOrg'] = orgnr;
};

export const setLogTilbudId = tilbudId => {
    defaultHeaders['X-TN'] = tilbudId;
};

export class FetchError extends Error {
    status: number;
    details: Response | Error;

    constructor(message: string, status: number, details: Response | Error) {
        super(message);
        this.status = status;
        this.details = details;
    }
}

interface Overrides {
    headers?: {};
}

type Data = object | [];

const removeNullValues = (data: Data): Data => {
    if (Array.isArray(data)) {
        return data.map(removeNullValues);
    }
    if (!data || typeof data !== 'object') {
        return data;
    }

    const copy = { ...data };

    Object.keys(copy).forEach((key) => {
        if (copy[key] === null) {
            delete copy[key];
        }
    });
    return copy;
};

const isError = (response: Response) => response.status >= 400;

const textOrJson = (response: Response) => {
    const contentType = response.headers.get('Content-Type') || '';
    if (contentType.includes('json')) {
        return response.json()
            .catch((e) => {
                throw new FetchError(
                    `Kan ikke parse JSON: ${e}`,
                    response.status,
                    response
                );
            });
    }
    return response.text();
};

const parse = (response: Response) => {
    setBackendVersjon(response.headers.get('x-app-version'));
    if (response.status === 204) {
        return null;
    }
    if (isError(response)) {
        return textOrJson(response)
            .then((error: Error) => {
                throw new FetchError(
                    response.statusText,
                    response.status,
                    error
                );
            });
    }
    return textOrJson(response);
};

const parseOnlyJson = (response: Response) => {
    if (response.status === 204) {
        return null;
    }
    const contentType = response.headers.get('Content-Type') || '';
    if (contentType.includes('json') || isError(response)) {
        return parse(response);
    }
    throw new Error('Forventet at header "Content-type" inneholder "json"');
};

const parseOnlyText = (response: Response) => {
    if (response.status === 204) {
        return null;
    }
    const contentType = response.headers.get('Content-Type') || '';
    if (contentType.includes('text') || isError(response)) {
        return parse(response);
    }
    throw new Error('Forventet at header "Content-type" inneholder "text"');
};

const screenInfo = window && window.screen && (window.screen.width + "x" + window.screen.height);

const getHeaders = (verb: string) => {
    const headers: object = {
        ...defaultHeaders,
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN'),
        'x-log-si': screenInfo
    };

    if (verb !== 'GET') {
        headers['Content-Type'] = 'application/json';
    }

    return headers;
};

const withGetParameters = (url: string, params = {}) => {
    if (Object.keys(params).length === 0) {
        return url;
    }
    const paramString = Object.keys(params).map(key => {
        return `${key}=${encodeURIComponent(params[key])}`;
    }).join('&');

    return `${url}?${paramString}`;
};

const doRequest = (verb: string) => (parser: (r: Response) => any) =>
    (url: string, data = {}, overrides: Overrides = {}) => {
        const defaults: RequestInit = {
            method: verb,
            credentials: 'same-origin',
            body: verb !== 'GET' && verb !== 'DELETE' ? JSON.stringify(data) : undefined
        };

        const config = {
            ...defaults,
            ...overrides,
            headers: new Headers({
                ...getHeaders(verb),
                ...overrides.headers
            })
        };

        return fetch(url, config)
            .catch(error => {throw new Error(`${error.message}: ${url}`); })
            .then(parser)
            .then(removeNullValues);
    };

export function get(url: string, data?: Data, overrides?: Overrides) {
    return doRequest('GET')(parse)(withGetParameters(url, data), {}, overrides);
}

export function getJson(url: string, data?: Data, overrides?: Overrides) {
    return doRequest('GET')(parseOnlyJson)(withGetParameters(url, data), {}, overrides);
}

export function getText(url: string, data?: Data, overrides?: Overrides) {
    return doRequest('GET')(parseOnlyText)(withGetParameters(url, data), {}, overrides);
}

export function post(url: string, data: Data, overrides?: Overrides) {
    return doRequest('POST')(parse)(url, data, overrides);
}

export function put(url: string, data: Data, overrides?: Overrides) {
    return doRequest('PUT')(parse)(url, data, overrides);
}

export function del(url: string, overrides: Overrides) {
    return doRequest('DELETE')(parse)(url, {}, overrides);
}
